export default {
  "about": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Front-end / iOS developer met een passie voor web en mobiele applicaties"])},
    "requestResume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CV aanvragen"])},
    "experiences": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ervaringen"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Met 5 jaar ervaring als front-end en iOS developer bij verschillende creatieve agencies, heb ik geleerd dat wij als creatieve developers het verschil en een echte impact kunnen maken met onze code. <br/><br/> Ik probeer mijzelf continu uit te dagen door nieuwe technieken te gebruiken en ben van nature altijd op zoek naar manieren om mijn workflow te verbeteren. <br/><br/> Als het om programmeren gaat ben ik secuur, efficiënt en kan ik zelfstandig aan projecten werken, maar ben ik ook een teamspeler, die het niet alleen leuk vind om kennis te vergaren maar het ook graag deelt met andere. Ook vind ik het erg interessant om kennis te vergaren buiten mijn discipline. Bijvoorbeeld vind ik het leuk om mij bezig te houden met user experience design, design systems en interacties met de eind gebruiker."])}
    },
    "programmingSkills": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programmeren"])}
    },
    "graphicDesign": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafisch"])}
    }
  },
  "generalAgreement": {
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algemene voorwaarden"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. <br/> <br/> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. <br/> <br/> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. <br/> <br/> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."])}
  },
  "footer": {
    "collaborate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samenwerken?"])},
    "generalAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algemene voorwaarden"])}
  }
}