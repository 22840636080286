export default {
  "about": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Front-end / iOS developer with a passion for web and mobile applications"])},
    "requestResume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request resume"])},
    "experiences": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiences"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With 5 years of experience as a front-end and iOS developer at various creative agencies, I have learned that we as creative developers can make a difference and make real impact with our code. <br/><br/> I continuously try to challenge myself by using new techniques and looking for ways to improve my workflow. <br/><br/> When it comes to programming, I’m precise, efficient and I can work on projects independently. At the same time I’m a team player who not only likes to gather knowledge, but also shares it with others. I also find it very interesting to gain knowledge outside of my discipline. For example I'm really into user experience design, design systems and interactions with the end user."])}
    },
    "programmingSkills": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programming skills"])}
    },
    "graphicDesign": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphic design"])}
    }
  },
  "generalAgreement": {
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General agreement"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. <br/> <br/> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. <br/> <br/> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. <br/> <br/> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."])}
  },
  "footer": {
    "collaborate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaborate?"])},
    "generalAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General agreement"])}
  }
}