
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'footer',
    computed: {
      currentYear() {
        const currentYear = new Date().getFullYear();
        
        return currentYear;
      }
    },
  });
