
  import { defineComponent } from 'vue';

  import Nav from '@/components/nav/Nav.vue';
  import Footer from '@/components/footer/Footer.vue';

  export default defineComponent({
    name: 'Home',
    components: {
      Nav,
      Footer,
    },
  });
